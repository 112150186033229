"use strict";

angular.module("informaApp")
    .service("DrugIndicationMapper", ['BoxPlotHelper', function (BoxPlotHelper) {
        function getGlobalMax(data) {
            var result = data.data;

            if (Array.isArray(data)){
                result = data.map(function(x) { return x.total; });
            }

            return Math.max.apply(null, result);
        }

        function getClassName(i, j) {
            var result = j == 0 ? '' : 'gray ';

            var classes = ['i', 'ii', 'iii', 'nda'];

            result += classes[i];

            return result;
        }

        function getSource(line, i) {
            var source = Array.isArray(line) ? line : [line];

            return {
                id: i,
                data: source.map(function (x, j) {
                    return {
                        value: x,
                        className: getClassName(i, j)
                    };
                }),
                total: _.sum(source)
            }
        }

        function processLine(line) {
            var source = line.map(getSource);

            return {
                source: source,
                max: getGlobalMax(source)
            }
        }

        function mapPhase(source) {
            return [source.phase1, source.phase2, source.phase3, source.ndabla];
        }

        function mapTransitions (numberOfTransitions) {
            return mapPhase(numberOfTransitions).map(function (z) {
                return [z.successed, z.failed]
            });
        }

        function map(source) {
            var duration = mapPhase(source.duration);
            var medianDuration = mapPhase(source.medianDuration);

            return {
                drugIndicationIds: source.drugIndicationIds,
                id: source.sourceId,
                name: source.name,
                
                drugId: source.drugId,
                drugBrandName: source.drugBrandName,
                diseaseId: source.diseaseId,
                diseaseShortName: source.diseaseShortName,
                diseaseGroup: source.diseaseGroup,
                eventPhaseName: source.eventPhaseName,
                indicationId: source.indicationId,
                indication: source.indication,
                companyId: source.companyId,
                companyName: source.companyName,
                leadCompany: source.leadCompany,

                pos: processLine(mapPhase(source.pos)),
                loa: processLine(mapPhase(source.loa)),
                durations: processLine(duration),
                medianDurations: processLine(medianDuration),
                boxPlotData: {
                    phase1: BoxPlotHelper.calculate(source.phaseDurations.phase1),
                    phase2: BoxPlotHelper.calculate(source.phaseDurations.phase2),
                    phase3: BoxPlotHelper.calculate(source.phaseDurations.phase3),
                    ndabla: BoxPlotHelper.calculate(source.phaseDurations.ndabla),
                },
                phaseDurations: source.phaseDurations,
                totalDuration: _.sum(duration),
                medianTotalDuration: _.sum(medianDuration),
                transitions: processLine(mapTransitions(source.numberOfTransitions)),
                uniqueDrugIndicationQuantity: source.uniqueDrugIndicationQuantity,
                originSearchName: source.originSearchName,
                POAEstimate: source.POAEstimate,
                POABaseline: source.POABaseline,
                POAFeature: source.POAFeature,
                POAOtherFeaturesInfluence: source.POAOtherFeaturesInfluence,
                POAOtherFeaturesRawInfluence: source.POAOtherFeaturesRawInfluence,
                POTSEstimate: source.POTSEstimate,
                POTSBaseline: source.POTSBaseline,
                POTSFeature: source.POTSFeature,
                POTSOtherFeaturesInfluence: source.POTSOtherFeaturesInfluence,
                POTSOtherFeaturesRawInfluence: source.POTSOtherFeaturesRawInfluence
            }
        }

        return {
            mapArray: function (source) {
                return source.map(function (x) {
                    return map(x);
                });
            }
        }
    }]);
